import { format } from "date-fns";
export var DUMMY_PROVIDER = {
    id: "",
    firstName: "David",
    lastName: "Jones",
    suffix: "",
    specialty: "",
    npi: "1942292412",
    indigoSpecialty: "Family Medicine-Minor Surgery",
    nppesCity: "DECATUR",
    nppesState: "IL",
    nppesSpecialty: "Family Medicine",
    deductible: "None",
    limitType: "Separate",
    limit: "_1M_3M",
    retroDate: "04/24/2001",
    scheduledRatingFactor: 0.19999999999999996,
    excludedNpi: false,
    address: {
        streetName: "1770 E Lake Shore Dr",
        city: "Decatur",
        state: "IL",
        zip: "62521",
        countyFips: "17115",
        countyName: "Macon",
    },
    boardActions: [
        {
            details: "Default on Health Education Loan or Scholarship Obligations",
            orderDate: "7/20/2005 12:00:00 AM",
            reportingEntity: "Illinois Department of Financial and Professional Regulation",
            severity: 2,
            summary: "RESTRICTIONS/LIMITATIONS PLACED ON PHYSICIAN ASSISTANT LICENSE/REGISTRATION/PERMIT",
        },
        {
            details: "Not Applicable",
            orderDate: "12/21/2006 12:00:00 AM",
            reportingEntity: "Illinois Department of Financial and Professional Regulation",
            severity: 1,
            summary: "PROBATION TERMINATED",
        },
    ],
};
export var DUMMY_PRODUCER = {
    id: "qaqG2Lz",
    name: "Johnny Utah",
    manager: "",
    managerId: "",
    producerId: "19003",
    email: "partime@example.com",
    agency: {
        name: "Keanu Insurance Group",
        agencyId: "00015154",
    },
};
export var DUMMY_QUOTE_PROPERTIES = {
    effectiveDate: format(new Date(), "MM/dd/yyyy"),
    modelInfo: {
        modelId: "65e57a483c00002700af2c50",
        modelVersion: "v1.1.6.2",
    },
};
export var DUMMY_SUBMISSION = {
    providers: [DUMMY_PROVIDER],
    producer: DUMMY_PRODUCER,
    quoteProperties: DUMMY_QUOTE_PROPERTIES,
};
export var MOCK_FULL_PROVIDER = [
    {
        id: "gqrwUUy",
        firstName: "John",
        lastName: "Smith",
        suffix: "1936",
        specialty: "Family",
        npi: "1134300510",
        indigoSpecialty: "Catheterization NOC",
        manualIndigoSpecialty: null,
        mrs: 0.22,
        manualMrs: null,
        newToPractice: null,
        nppesCity: "WILMETTE",
        nppesState: "IL",
        nppesSpecialty: "Plastic Surgery",
        deductible: "_25K",
        limitType: "Separate",
        limit: "_200K_600K",
        retroDate: "03/29/2001",
        scheduledRatingFactor: 0.5354,
        simplifyCustomerId: "C179140",
        excludedNpi: true,
        ignoreLocationInconsistency: null,
        cyberOptOut: null,
        partTimePractice: null,
        isUserClient: null,
        previewPremium: null,
        manualPreviewPremium: null,
        redFlagSearchCompleted: true,
        negativeFactors: [
            {
                name: "ASHRM state relativity for each NPI (high)",
                category: "Physician Characteristic - Individual",
                score: "0.752924",
                value: "2.0",
            },
            {
                name: "ASHRM primary specialty relativity",
                category: "Physician Characteristic - Individual",
                score: "0.690378",
                value: "3.0",
            },
            {
                name: "Political Orientation, County Level 2020 - Other Votes",
                category: "Population - Socioeconomic",
                score: "0.422076",
                value: "7308.0",
            },
            {
                name: "Gender",
                category: "Physician Characteristic - Individual",
                score: "0.227311",
                value: "M",
            },
            {
                name: "NUCC Category of Practitioner",
                category: "Physician Characteristic - Individual",
                score: "0.21304",
                value: "Plastic Surgery",
            },
            {
                name: "NUCC Grouping",
                category: "Physician Characteristic - Individual",
                score: "0.189842",
                value: "Allopathic & Osteopathic Physicians",
            },
            {
                name: "Social Factor: Durable Medical Equipment Reimbursements as a Percentage of Total Spend",
                category: "Population - Health",
                score: "0.132911",
                value: "0.0289825086032062",
            },
            {
                name: "HV - Total Medical Billing Claim Count (3Y)",
                category: "Practice of Medicine - Patient Volume",
                score: "0.0757",
                value: "261",
            },
            {
                name: "Social Factor: Expenditure per capita on Fast-Food 2012",
                category: "Population - Health",
                score: "0.068624",
                value: "608.7322135075386",
            },
            {
                name: "ASHRM primary specialty",
                category: "Physician Characteristic - Individual",
                score: "0.064861",
                value: "Plastic Surgery - Major Surgery",
            },
        ],
        mrsTrends: [
            {
                version: "MRS Trend - 2024",
                value: 0.103292904438742,
            },
            {
                version: "MRS Trend - 2023",
                value: 0.16719237332053333,
            },
            {
                version: "MRS Trend - 2022",
                value: 0.15176309718544204,
            },
        ],
        billingData: {
            hvData: [
                {
                    year: "2024",
                    amount: "null",
                },
                {
                    year: "2023",
                    amount: "0",
                },
            ],
            payerData: [
                {
                    year: "2023",
                    amount: "null",
                },
                {
                    year: "2022",
                    amount: "null",
                },
                {
                    year: "2021",
                    amount: "null",
                },
            ],
        },
        inconsistencies: {
            location: [
                {
                    ds: "Cook, IL",
                    iqvia: "",
                    inferred: "",
                    declared: "Cook, IL",
                    isConsistent: true,
                },
            ],
            specialty: [
                {
                    nppes: "Plastic Surgery",
                    dhc: "",
                    inferred: "Radiation Therapy NOC",
                    declared: "Catheterization NOC",
                    ds: "Plastic NOC-Surgery",
                    isConsistent: null,
                },
            ],
        },
        address: {
            streetName: "W Addison St",
            secondary: null,
            city: "Chicago",
            state: "IL",
            zip: "60613",
            countyFips: "17031",
            countyName: "Cook",
            isSecondary: null,
        },
        redFlags: [
            {
                displayLink: "https://casetext.com › ... › IL › App. Ct. › 2021 › January",
                htmlTitle: "People v. Smith, 2021 Ill. App. 180884",
                htmlSnippet: "Defendant John Smith was found guilty of five counts of armed robbery...",
                link: "https://casetext.com/case/people-v-smith-7964",
                snippet: "Defendant John Smith was found guilty of five counts of armed robbery (720 ILCS 5/18-2(a)(2), (3), (4) (West 2012)), and not guilty of five counts of attempt ...",
                title: "People v. Smith, 2021 Ill. App. 180884",
                redFlagSummary: "John Smith was found guilty of five counts of armed robbery.",
                query: "John Smith II DO Illinois lawsuit",
                date: "Jan 26, 2021",
            },
            {
                displayLink: "https://www.pretzel-stouffer.com › News",
                htmlTitle: "Summary Judgement Won and Upheld Due to Lack of ...",
                htmlSnippet: "The plaintiff alleged that a series of doctors had been negligent in failing to diagnose or properly treat a vascular condition on his leg, preventing him from ...",
                link: "https://www.pretzel-stouffer.com/news/summary-judgement-won-and-upheld-due-to-lack-of-medical-causation/",
                snippet: "The Appellate Court of Illinois has affirmed summary judgment for several doctors and professional corporations finding that ...",
                title: "Summary Judgement Won and Upheld Due to Lack of ...",
                redFlagSummary: "This case involves a series of negligence allegations against a group of doctors, suggesting involvement in medical malpractice claims.",
                query: "John Smith II DO Illinois malpractice",
                date: "January 2018",
            },
            {
                displayLink: "https://www.fgppr.com › Attorneys",
                htmlTitle: "John Smith",
                htmlSnippet: "John Smith and Matthew Reddy Continue Winning Streak with Not-Guilty Verdict in $3.14 M Case",
                link: "https://fgppr.com/attorney/john-smith/",
                snippet: "John Smith and Matthew Reddy Continue Winning Streak with Not-Guilty Verdict in $3.14 M Case.",
                title: "John Smith",
                redFlagSummary: "Involved in a high-stakes legal case with a claim amount of $3.14 million, indicating potential exposure to significant liability.",
                query: "John Smith II DO Illinois malpractice",
                date: "May 18, 2023",
            },
            {
                displayLink: "https://www.fgppr.com › Attorneys",
                htmlTitle: "John Smith",
                htmlSnippet: "John Smith and Matthew Reddy Obtain Back-to-Back Not Guilty Verdicts in Multimillion Dollar Claims",
                link: "https://fgppr.com/attorney/john-smith/",
                snippet: "John Smith and Matthew Reddy Obtain Back-to-Back Not Guilty Verdicts in Multimillion Dollar Claims.",
                title: "John Smith",
                redFlagSummary: "Repeated involvement in multimillion dollar claims suggests a pattern of high-risk legal situations.",
                query: "John Smith II DO Illinois malpractice",
                date: "June 16, 2022",
            },
            {
                displayLink: "https://casetext.com › ... › IL › App. Ct. › 2021 › January",
                htmlTitle: "People v. Smith, 2021 Ill. App. 180884",
                htmlSnippet: "Defendant John Smith was found guilty of five counts of armed robbery (720 ILCS 5/18-2(a)(2), (3), (4) (West 2012)), and not guilty of five counts of attempt ...",
                link: "https://casetext.com/case/people-v-smith-7964",
                snippet: "Defendant John Smith was found guilty of five counts of armed robbery (720 ILCS 5/18-2(a)(2), (3), (4) (West 2012)), and not guilty of five counts of attempt first degree murder. The trial court merged the armed robbery counts and sentenced defendant to a mandatory term of life imprisonment based on his criminal history.",
                title: "People v. Smith, 2021 Ill. App. 180884",
                redFlagSummary: "Found guilty of five counts of armed robbery and sentenced to life imprisonment.",
                query: "John Smith II DO Illinois complaint",
                date: "Jan 26, 2021",
            },
            {
                displayLink: "https://caselaw.findlaw.com › ... › US 7th Cir.",
                htmlTitle: "UNITED STATES v. SMITH (2016)",
                htmlSnippet: "John Smith was found guilty by a jury of distributing heroin, see 21 U.S.C. ...",
                link: "https://caselaw.findlaw.com/court/us-7th-circuit/1729663.html",
                snippet: "John Smith was found guilty by a jury of distributing heroin, see 21 U.S.C. ...",
                title: "UNITED STATES v. SMITH (2016)",
                redFlagSummary: "Found guilty by jury of distributing heroin.",
                query: "John Smith II DO Illinois complaint",
                date: "Mar 21, 2016",
            },
            {
                displayLink: "https://www.isba.org › barnews › 2017/09/22 › illinois-...",
                htmlTitle: "Illinois Supreme Court Disbars 15, Suspends 35 ...",
                htmlSnippet: "Mr. Smith, who was licensed in 2015, was suspended for one year and until further order of the Court. He threatened to split his estranged wife ...",
                link: "https://www.isba.org/barnews/2017/09/22/illinois-supreme-court-disbars-15-suspends-35-reprimands-2-censures-4-latest-discipl",
                snippet: "Mr. Smith, who was licensed in 2015, was suspended for one year and until further order of the Court. He threatened to split his estranged wife ...",
                title: "Illinois Supreme Court Disbars 15, Suspends 35 ...",
                redFlagSummary: "Mr. Smith was suspended for one year due to professional misconduct involving a threat towards his estranged wife.",
                query: "John Smith II DO Illinois suspension",
                date: "Sep 22, 2017",
            },
            {
                displayLink: "https://idfpr.illinois.gov › idfpr › forms › discpln",
                htmlTitle: "",
                htmlSnippet: "",
                link: "https://idfpr.illinois.gov/content/dam/soi/en/web/idfpr/forms/discpln/2023-07enf.pdf",
                snippet: "",
                title: "DIVISION OF FINANCIAL INSTITUTIONS",
                redFlagSummary: "John Smith's permanent employee registration card was suspended for being more than 30 days delinquent in child support payments.",
                query: "John Smith II DO Illinois suspension",
                date: "2023-07-07",
            },
            {
                displayLink: "https://fgppr.com › Attorneys",
                htmlTitle: "John Smith",
                htmlSnippet: "Latest News May 18, 2023 John Smith and Matthew Reddy Continue Winning Streak with Not-Guilty Verdict in $3.14 M Case",
                link: "https://fgppr.com/attorney/john-smith/",
                snippet: "Overview. John Smith concentrates his practice in civil litigation with extensive experience in medical malpractice. With more than 35 years of experience ...",
                title: "John Smith",
                redFlagSummary: "Recently won a Not-Guilty verdict in a high-stakes medical malpractice case, which could point to the severity of claims involving him.",
                query: "John Smith II DO Illinois negligence",
                date: "May 18, 2023",
            },
            {
                displayLink: "https://fgppr.com › Attorneys",
                htmlTitle: "John Smith",
                htmlSnippet: "Latest News June 16, 2022 John Smith and Matthew Reddy Obtain Back-to-Back Not Guilty Verdicts in Multimillion Dollar Claims",
                link: "https://fgppr.com/attorney/john-smith/",
                snippet: "Overview. John Smith concentrates his practice in civil litigation with extensive experience in medical malpractice. With more than 35 years of experience ...",
                title: "John Smith",
                redFlagSummary: "Secured multiple Not Guilty verdicts in multimillion dollar medical malpractice claims, indicating a history of facing serious accusations.",
                query: "John Smith II DO Illinois negligence",
                date: "June 16, 2022",
            },
            {
                displayLink: "https://casetext.com › ... › ND IL › 2017 › November",
                htmlTitle: "United States v. Smith",
                htmlSnippet: "John Smith, a federal inmate proceeding pro se, has filed a motion to vacate, set aside, or correct his sentence under 28 U.S.C. § 2255. A jury found Smith guilty on four counts of heroin distribution...",
                link: "https://casetext.com/case/united-states-v-smith-2030",
                snippet: "John Smith, a federal inmate proceeding pro se, has filed a motion to vacate, set aside, or correct his sentence under 28 U.S.C. § 2255. A jury found Smith guilty on four counts of heroin distribution, in violation of 21 U.S.C. § 841(a)(1).",
                title: "United States v. Smith",
                redFlagSummary: "John Smith has a conviction for heroin distribution, which indicates a serious criminal history.",
                query: '"John Smith" II DO Illinois lawsuit',
                date: "Nov 28, 2017",
            },
            {
                displayLink: "https://law.justia.com › cases › federal › appellate-courts",
                htmlTitle: "John Smith v. Craig Asselmeier, No. 18-2832 (7th Cir. 2019)",
                htmlSnippet: "O R D E R John Smith, an Illinois inmate, sued Dr. Craig Asselmeier...",
                link: "https://law.justia.com/cases/federal/appellate-courts/ca7/18-2832/18-2832-2019-04-11.html",
                snippet: "John Smith, an Illinois inmate, sued Dr. Craig Asselmeier, a dentist at Menard Correctional Center in Chester, Illinois, under 42 U.S.C. § 1983 for violating the Eighth Amendment...",
                title: "John Smith v. Craig Asselmeier, No. 18-2832 (7th Cir. 2019)",
                redFlagSummary: "John Smith has previously been involved in legal actions in a correctional context, raising concerns about his professional conduct.",
                query: '"John Smith" II DO Illinois lawsuit',
                date: "Apr 10, 2019",
            },
            {
                displayLink: "https://caselaw.findlaw.com › ... › AK Supreme Ct.",
                htmlTitle: "CYNTHIA HORA v. JOHN SMITH (2011)",
                htmlSnippet: "Case opinion for AK Supreme Court CYNTHIA HORA v. JOHN SMITH. Read the Court's full decision on FindLaw.",
                link: "https://caselaw.findlaw.com/ak-supreme-court/1587965.html",
                snippet: "Case opinion for AK Supreme Court CYNTHIA HORA v. JOHN SMITH. Read the Court's full decision on FindLaw.",
                title: "CYNTHIA HORA v. JOHN SMITH (2011)",
                redFlagSummary: "This case suggests ongoing legal issues associated with John Smith, indicating a potentially problematic professional history.",
                query: '"John Smith" II DO Illinois lawsuit',
                date: "Dec 7, 2011",
            },
            {
                displayLink: "https://fgppr.com › Attorneys",
                htmlTitle: "John Smith",
                htmlSnippet: "John Smith and Matthew Reddy Continue Winning Streak with Not-Guilty Verdict in $3.14 M Case",
                link: "https://fgppr.com/attorney/john-smith/",
                snippet: "In a recent medical malpractice trial, John Smith and Matthew Reddy secured a not-guilty verdict...",
                title: "John Smith",
                redFlagSummary: "John Smith was involved in a medical malpractice trial where the team secured a not-guilty verdict for their client, indicating previous allegations of malpractice.",
                query: '"John Smith" II DO Illinois malpractice',
                date: "May 18, 2023",
            },
            {
                displayLink: "https://tax.illinois.gov › tax › hearings › documents",
                htmlTitle: "ST 01-19 - Responsible Corporate Officer - Failure to File or",
                htmlSnippet: 'JOHN SMITH (hereinafter referred to as "SMITH") was the treasurer ... paying the taxes, and (2) the individual willfully failed to perform these ...',
                link: "https://tax.illinois.gov/content/dam/soi/en/web/tax/research/legalinformation/hearings/st/documents/st01-19.pdf",
                snippet: "",
                title: "ST 01-19 - Responsible Corporate Officer - Failure to File or",
                redFlagSummary: "John Smith was involved in a penalty case regarding failure to file taxes as a responsible corporate officer.",
                query: '"John Smith" II DO Illinois sanction',
                date: "Mar 10, 1995",
            },
            {
                displayLink: "https://caselaw.findlaw.com › ... › US 7th Cir.",
                htmlTitle: "UNITED STATES v. SMITH (2015)",
                htmlSnippet: "John Smith was arrested after a sting operation in which the ...",
                link: "https://caselaw.findlaw.com/court/us-7th-circuit/1707112.html",
                snippet: "John Smith was arrested after a sting operation related to illegal activities, indicating potential criminal involvement.",
                title: "UNITED STATES v. SMITH (2015)",
                redFlagSummary: "John Smith was arrested after a sting operation concerning illegal activities.",
                query: '"John Smith" II DO Illinois criminal',
                date: "Jul 7, 2015",
            },
            {
                displayLink: "https://casetext.com/case/coryell-v-smith",
                htmlTitle: "Coryell v. Smith, 274 Ill. App. 3d 543",
                htmlSnippet: "... plaintiff had developed lower back pain ...  the proposed surgery entailed undermining all of the skin ... the trial court granted defendants' motion for summary judgment...",
                link: "https://casetext.com/case/coryell-v-smith",
                snippet: "Coryell v. Smith, 274 Ill. App. 3d 543 - Medical malpractice action regarding informed consent related to surgery.",
                title: "Coryell v. Smith, 274 Ill. App. 3d 543",
                redFlagSummary: "In a medical malpractice case, John Smith was involved in a lawsuit regarding failure to obtain informed consent for surgery, resulting in a summary judgment being granted to the defendants.",
                query: '"John Smith" II DO Illinois negligence',
                date: "",
            },
        ],
        boardActions: [
            {
                npiSource: "NPPES_LICENSE_NUMBER_MATCH",
                termLength: null,
                state: null,
                details: "Violation of probation (failure to report patient complaint) and revocation of California medical license",
                orderDate: "2015-04-30",
                reportingEntity: "IL Medical Board",
                severity: null,
                summary: "Must voluntarily relinquish license",
                npi: "1134300510",
            },
        ],
        stateLicenses: [],
        claims: [
            {
                id: "24026317a369fe614dbe55160404a6a8",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2003-03-28",
            },
            {
                id: "bebc67875f1c1aeda4bd46b86bbd9715",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "1995-08-30",
            },
            {
                id: "0c847f41c73b3ca44acaf7fd4022d280",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2000-06-22",
            },
            {
                id: "4fa4b99781421057dba7048f5a15b9f1",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "1997-07-11",
            },
            {
                id: "dfe30971e7dc7c716a337ca1cf0a7893",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2015-04-15",
            },
            {
                id: "41f76a7ee9070b209ed557a1aebc0531",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2002-03-25",
            },
            {
                id: "1c6503e4667d2bd12b8f34c41fc7a388",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "1999-09-07",
            },
            {
                id: "d1c135dc18806808d18b5690240f08c1",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "1998-08-06",
            },
            {
                id: "f532a7d4caf22835abf7d6c4ef383912",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2003-01-15",
            },
            {
                id: "1bf8d403bdab13024f57d28afac25c9b",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2002-07-29",
            },
            {
                id: "fde64581ae7430dc371565a1d1265f74",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2000-04-14",
            },
            {
                id: "65addca1ffd83fe45185ce5e7789a192",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2004-12-17",
            },
            {
                id: "989d3f1719cf35cac8de8395e7a7b5d7",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2009-04-14",
            },
            {
                id: "9e8c4f01c26d7a5740a61c0253457221",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2004-05-26",
            },
            {
                id: "eaf9d1d925b985c35d14840f3a0a0d94",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "2000-01-12",
            },
            {
                id: "b78c9cb71c2363a0f9143a7f4c42ebbc",
                npi: "1134300510",
                claim: null,
                reason: "",
                severity: null,
                claimState: "",
                dataSource: "MRS_DATA",
                indemnityPaid: 0,
                incidentDate: "1997-06-20",
            },
        ],
    },
];
