import { useCallback, useContext, useState, useEffect } from "react";
import { GoTriangleDown } from "react-icons/go";
import { subMonths, isAfter, addDays, isBefore, parse } from "date-fns";
import {
  Spinner,
  Button,
  Box,
  Input,
  Badge,
  Collapse,
  Select,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardHeader,
  IconButton,
  Text,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useDisclosure,
} from "@chakra-ui/react";
import { Datum, DatePicker } from "components";
import { WarningIcon, ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  SunlightBrokersResponseAgency,
  SunlightBrokersResponseBroker,
} from "@app-stack/types/sunlight";
import { formatErrors } from "utils";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form";
import { CARRIERS } from "app-constants";
import { ApiClientContext } from "providers";
import { InputMaybe, ProducerUpdateInput, SubmissionUpdateInput } from "__generated__/graphql";

interface ProducerFormProps {
  setValue?: UseFormSetValue<SubmissionUpdateInput>;
  register: UseFormRegister<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  onChangeSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  producer?: InputMaybe<ProducerUpdateInput>;
  control: Control<SubmissionUpdateInput>;
  clearErrors: UseFormClearErrors<SubmissionUpdateInput>;
  isRatSubmission?: boolean;
  latestRetroDate: string;
}

export function ProducerForm({
  isRatSubmission,
  setValue,
  producer,
  onChangeSelect,
  errors,
  register,
  control,
  clearErrors,
  latestRetroDate,
}: ProducerFormProps) {
  const apiClient = useContext(ApiClientContext);
  const [agencies, setAgencies] = useState<SunlightBrokersResponseAgency[]>([]);
  const [brokers, setBrokers] = useState<SunlightBrokersResponseBroker[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { onOpen, isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const e = errors?.producer;
  const fetchAgents = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await apiClient?.getSunlightAgents();
      if (response) {
        if (response.agencies) {
          setAgencies(response.agencies);
        }
        if (response.brokers) {
          setBrokers(response.brokers);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);
  useEffect(() => {
    if (e) {
      onOpen();
    }
  }, [e, onOpen]);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  return !isOpen ? (
    <Card
      as={Button}
      onClick={onToggle}
      w="100%"
      aria-label="expand provider review card"
      variant="ghost"
      alignItems="start"
      h="92px"
      m="16px 0px 0px 0px"
      p="10px 20px 20px 20px"
      style={{
        boxShadow:
          "1px 1px 3px 0px rgba(0, 0, 0, 0.10), 1px 1px 3px 0px rgba(0, 0, 0, 0.10) !important",
      }}
      _hover={{ background: "white" }}
    >
      <CardHeader p="0" w="100%">
        <Flex p="0" w="100%" justifyContent="space-between" alignItems="center">
          <Text lineHeight="none" fontSize="3xl" p="0">
            Quote Summary
          </Text>
          <Flex gap="16px">
            {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
            <Box w="40px" h="40px" pt="6px" pl="2px">
              <ChevronDownIcon fontSize="1.5rem" />
            </Box>
          </Flex>
        </Flex>
      </CardHeader>
    </Card>
  ) : (
    <Card bgColor="white" borderRadius="3px" variant="outline" pb="12px">
      <CardHeader pb="0">
        <Flex justifyContent="space-between" alignItems="center">
          <Text lineHeight="none" fontSize="3xl">
            Quote Summary
          </Text>
          <Flex gap="16px">
            {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
            {!isOpen ? (
              <IconButton
                onClick={onToggle}
                variant="ghost"
                aria-label="Expand provider"
                fontSize="1.5rem"
                m="0px"
                icon={<ChevronDownIcon />}
              />
            ) : (
              <IconButton
                onClick={onToggle}
                variant="ghost"
                aria-label="Collapse provider"
                fontSize="1.5rem"
                m="0px"
                icon={<ChevronUpIcon />}
              />
            )}
          </Flex>
        </Flex>
      </CardHeader>
      <Collapse in={isOpen} animateOpacity>
        <CardBody pb="0px" pt="12px">
          {producer?.email && (
            <Flex
              bgColor="indigo.100"
              gap="24px"
              padding="16px 8px"
              border="1px solid"
              borderColor="gray.200"
              mb="10px"
            >
              <Datum label="Broker Email" value={producer?.email} />
            </Flex>
          )}
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
            gap="12px"
          >
            <GridItem colSpan={3}>
              <FormControl isInvalid={!!e?.agency?.name?.message}>
                <FormLabel
                  htmlFor="producer.agency.name"
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                >
                  Agency *
                </FormLabel>
                <Select
                  icon={
                    isLoading ? <Spinner fontSize="1rem" color="indigo.500" /> : <GoTriangleDown />
                  }
                  data-cy="agency-select"
                  id="producer.agency.name"
                  size="md"
                  m="0px"
                  p="0px"
                  {...register("producer.agency.name", {
                    required: "Agency selection is required",
                  })}
                  value={producer?.agency?.name || ""}
                  onChange={(event) => {
                    clearErrors("producer.agency.name");
                    setValue?.(
                      "producer.agency.agencyId",
                      agencies.find((a) => a.name === event.target.value)?.agencyCode || "",
                    );

                    // Reset downstream values if agency changes
                    setValue?.("producer.producerId", "");
                    setValue?.("producer.name", "");
                    setValue?.("producer.manager", "");
                    setValue?.("producer.managerId", "");

                    if (onChangeSelect) {
                      return onChangeSelect(event);
                    } else {
                      return register("producer.agency.name").onChange(event);
                    }
                  }}
                >
                  <option></option>
                  {agencies
                    .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()))
                    .map((agency) => (
                      <option key={agency.agencyCode} value={agency.name}>
                        {agency.name}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>
                  <WarningIcon fontSize="xs" mr="3.5px" mt="3.5px" color="red.500" />
                  {e?.agency?.name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl isInvalid={!!e?.name?.message}>
                <FormLabel htmlFor="producer.name" fontSize="md" mb="0px" fontWeight="normal">
                  Producer Name *
                </FormLabel>
                <Select
                  icon={
                    isLoading ? <Spinner fontSize="1rem" color="indigo.500" /> : <GoTriangleDown />
                  }
                  data-cy="producer-select"
                  disabled={!producer?.agency?.agencyId}
                  id="producer.name"
                  size="md"
                  m="0px"
                  p="0px"
                  {...register("producer.name", {
                    required: "Producer name selection is required",
                  })}
                  value={producer?.name || ""}
                  onChange={(event) => {
                    clearErrors("producer.name");
                    setValue?.(
                      "producer.producerId",
                      brokers.find((a) => a.name === event.target.value)?.brokerCode || "",
                    );
                    if (!isRatSubmission) {
                      setValue?.(
                        "producer.email",
                        brokers.find((a) => a.name === event.target.value)?.email || "",
                      );
                    }
                    if (onChangeSelect) {
                      return onChangeSelect(event);
                    } else {
                      return register("producer.name").onChange(event);
                    }
                  }}
                >
                  <option></option>
                  {brokers
                    .filter((broker) => broker.agencyCode === producer?.agency?.agencyId)
                    .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()))
                    .map((broker) => (
                      <option key={broker.brokerCode} value={broker.name}>
                        {broker.name}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>
                  <WarningIcon fontSize="xs" mr="3.5px" mt="3.5px" color="red.500" />
                  {e?.name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel htmlFor="producer.manager" fontSize="md" mb="0px" fontWeight="normal">
                  Account Manager
                </FormLabel>
                <Select
                  icon={
                    isLoading ? <Spinner fontSize="1rem" color="indigo.500" /> : <GoTriangleDown />
                  }
                  disabled={!producer?.agency?.agencyId}
                  id="producer.manager"
                  size="md"
                  m="0px"
                  p="0px"
                  {...register("producer.manager")}
                  value={producer?.manager || ""}
                  onChange={(event) => {
                    setValue?.(
                      "producer.managerId",
                      brokers.find((a) => a.name === event.target.value)?.brokerCode || "",
                    );
                    if (onChangeSelect) {
                      return onChangeSelect(event);
                    } else {
                      return register("producer.manager").onChange(event);
                    }
                  }}
                >
                  <option></option>
                  {brokers
                    .filter((broker) => broker.agencyCode === producer?.agency?.agencyId)
                    .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()))
                    .map((broker) => (
                      <option key={broker.brokerCode} value={broker.name}>
                        {broker.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <Controller
                rules={{
                  required: "Effective Date is required",
                  validate: (value) => {
                    if (value) {
                      const oneMonthAgo = subMonths(new Date(), 1);
                      const futureLimit = addDays(new Date(), 120);
                      const retroDate = parse(latestRetroDate, "MM/dd/yyyy", new Date());

                      if (!isAfter(new Date(value), oneMonthAgo)) {
                        return "Effective Date cannot be more than 1 month old";
                      }
                      if (!isBefore(new Date(value), futureLimit)) {
                        return "Effective Date cannot be more than 120 days in the future";
                      }
                      if (!isAfter(new Date(value), retroDate)) {
                        return "Effective Date must be after the latest retro date";
                      }
                      return true;
                    }
                  },
                }}
                render={({ fieldState, field }) => (
                  <FormControl isInvalid={Boolean(fieldState.error)}>
                    <FormLabel
                      htmlFor={`quoteProperties.effectiveDate`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Effective Date *
                    </FormLabel>
                    <DatePicker
                      id="quote-effective-date-field"
                      invalid={Boolean(fieldState.error)}
                      selected={field?.value || ""}
                      onChange={(date: string) => {
                        clearErrors("quoteProperties.effectiveDate");
                        setValue?.(`quoteProperties.effectiveDate`, date);
                      }}
                    />
                    <FormErrorMessage>
                      <WarningIcon fontSize="xs" mr="3.5px" mt="3.5px" color="red.500" />
                      {fieldState.error && fieldState.error.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
                control={control}
                name={`quoteProperties.effectiveDate`}
              />
            </GridItem>
          </Grid>
        </CardBody>
        <CardBody pt="12px" pr="10px" pb="15">
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
            gap="12px"
          >
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel
                  htmlFor={`quoteProperties.contactEmail`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Contact Email
                </FormLabel>
                <Input {...register(`quoteProperties.contactEmail`)} />
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel
                  htmlFor={`quoteProperties.carrier`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Current Carrier
                </FormLabel>
                <Select
                  icon={
                    isLoading ? <Spinner fontSize="1rem" color="indigo.500" /> : <GoTriangleDown />
                  }
                  id={`quoteProperties.currentCarrier`}
                  size="md"
                  m="0px"
                  p="0px"
                  {...register(`quoteProperties.currentCarrier`)}
                  onChange={onChangeSelect}
                >
                  {CARRIERS.map(({ name, id }) => {
                    return (
                      <option key={id} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel
                  htmlFor={`quoteProperties.expiringPremium`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Expiring Premium
                </FormLabel>
                <Input
                  type="number"
                  {...register(`quoteProperties.expiringPremium`, { valueAsNumber: true })}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel
                  htmlFor={`quoteProperties.targetPremium`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Target Premium
                </FormLabel>
                <Input
                  type="number"
                  {...register(`quoteProperties.targetPremium`, { valueAsNumber: true })}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel
                  htmlFor={`quoteProperties.renewalPremium`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Renewal Premium
                </FormLabel>
                <Input
                  type="number"
                  {...register(`quoteProperties.renewalPremium`, { valueAsNumber: true })}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </CardBody>
      </Collapse>
    </Card>
  );
}
